/* eslint-disable no-undef, react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { css } from 'react-emotion';
import { Box } from '../components/Layout';
import PageWrapper from '../components/PageWrapper';
import colors from '../utils/colors';

const imgStyle = css`
  border-radius: 5px;
`;

const About = ({ data }) => {
  const imageData = data.file.childImageSharp;
  return (
    <PageWrapper>
      <Box bg={colors.primary}>
        <Box
          width={[1, 1, 1 / 2]}
          m={['3.5rem 0 0 0', '3.5rem 0 0 0', '3.5rem auto 0 auto']}
          px={[3, 3, 0]}
          color={colors.secondary}
        >
          <h1>About</h1>
          <h2>2021 Officers</h2>
          <ul>
            <li>Worshipful Master</li>
            <li>Senior Warden</li>
            <li>Junior Warden</li>
            <li>Treasurer</li>
            <li>Secretary</li>
            <li>Chaplain</li>
            <li>Senior Deacon</li>
            <li>Junior Deacon</li>
            <li>Senior Steward</li>
            <li>Junior Steward</li>
            <li>Marshall</li>
            <li>Master of Ceremonies</li>
            <li>Musician</li>
            <li>Tiler</li>
          </ul>
          <h2>History</h2>
          <p>Alamo Lodge #44 is the first and oldest Masonic Lodge in the City of San Antonio (Bexar County) and has a rich and unique history due to having been founded in the the historic Alamo, the Shrine of Texas Liberty. The lodge was granted a charter from the Grand Lodge of Texas on January 15, 1848 and is now honored by a plaque on the south wall of the Alamo’s Long Barrack.</p>
          <p>In the 1840’s, troops returning from the Mexican War leased the Alamo to be used by the Quartermaster Corps for storing and shipping army supplies.  The quartermaster was Major James Harvey Ralston, who had served in both houses of the Illinois legislature with both Lincoln and Douglas.  Ralston was a mason as were many of the army officers and some local citizens.  Those masons, with Major Ralston as the prime organizer, formed Alamo Masonic Lodge. The lodge room was on the second floor of the Nun’s quarters also referred to as the long barracks.</p>
          <p>The Quartermaster Corp put the first roof on the Alamo Chapel and contracted with Joseph Kampmann, a German immigrant and builder, to rebuild the upper portion of the Alamo façade, which had been toppled by General Cos when he fortified the Alamo before being run out of town by Benjamin Milam, a brother mason, and his 300 volunteers in 1835.  Joseph Kampmann later became a member of Alamo Lodge and he and his wife are buried in the Alamo Lodge cemetery.</p>
          <p>In 1899 Clara Driscoll began her drive to save the Alamo and its grounds from being sold to a hotel company.  Ms. Driscoll and the other members of the Daughters of the Republic of Texas raised some of the money needed to save the Alamo, the rest of which Ms. Driscoll provided from her own funds and  by signing promissary notes.  A portrait of Clara Driscoll hangs in the Senate Chambers of the capitol in Austin along with that of Sam Houston.  Her portrait is titled “Savior of the Alamo”.  Clara Driscoll is buried in the Alamo Lodge cemetery in San Antonio.</p>
          <p>Two past masters of Alamo Lodge #44 have served as Grand Masters of Texas: James H. McLeary (1881), and A. W. Houston (1914).</p>
          <p>Masons at the Alamo included James B. Bonham, James Bowie, David Crockett, Almeron Dickinson, and William B. Travis.</p>
          <p>Masons continued to play a significant leadership role in the Republic of Texas. According to The New Handbook of Texas: “Although constituting only about 1% of the population [of Texas], Masons filled some 80% of the republic’s higher offices. All of the presidents, vice presidents, and secretaries of state were Masons.”</p>
          <Img
            className={imgStyle}
            alt="Picture of X"
            fluid={imageData.fluid}
          />
          <h2>Past Masters</h2>
          <ul>
            <li>2021</li>
            <li>2020</li>
            <li>2019</li>
            <li>2018 Robert M. Park</li>
            <li>2017 Tony Kimball</li>
            <li>2016 Anthony Harris</li>
            <li>2015 Michael Gollahon</li>
            <li>2014 Max M. Charleswell Jr.</li>
            <li>2013 Brady Johnson</li>
            <li>2012 Gregory D. Wright</li>
            <li>2011 Gregory D. Wright</li>
            <li>2010 Scott Mikos</li>
            <li>2009 Larry McNamee</li>
            <li>2008 Michael Goodrich</li>
            <li>2007 Jaime Garza</li>
            <li>2006 Edward V. O’Banion</li>
            <li>2005 Roland G. Havens</li>
            <li>2004 Armando Soto Sr.</li>
            <li>2003 Daniel K. Schauer</li>
            <li>2002 Eulalio C. Gonzalez</li>
            <li>2001 Steven C. Stanphill</li>
            <li>2000 William F. Jamison</li>
            <li>1999 Robert M. DeWalt</li>
            <li>1998 Guadalupe Gutierrez</li>
            <li>1997 Eulalio C. Gonzalez</li>
            <li>1996 Robert M. DeWalt</li>
            <li>1995 C.R. McCormick II</li>
            <li>1994 Rulalio C. Gonzalez</li>
            <li>1993 Jesus A. Garza</li>
            <li>1992 Jesus A. Araiza</li>
            <li>1991 Chas. A. Young</li>
            <li>1990 Henry W. Pooley</li>
            <li>1989 Donald R. Webster</li>
            <li>1988 Charles A. Kee</li>
            <li>1987 Kenneth R. Tucke</li>
            <li>1986 Charles A. Young</li>
            <li>1985 W.G. Howeth Jr.</li>
            <li>1984 George H. Trumbo Jr.</li>
            <li>1983 Gordon C. Ellis</li>
            <li>1982 John B. Searson</li>
            <li>1981 Donald L. Lively</li>
            <li>1980 James N. Higdon</li>
            <li>1979 Conrad Carr</li>
            <li>1978 Robert L. Wand</li>
            <li>1977 Thomas H. Bailey</li>
            <li>1976 D.A. Schwarz II</li>
            <li>1975 O.D. Cresswell Jr.</li>
          </ul>
        </Box>
      </Box>
    </PageWrapper>
  );
};

export const query = graphql`
  query {
    file(relativePath: { eq: "Plaque-A44.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default About;
